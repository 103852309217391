import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../../components/Typography.js';
import Button from '../../components/Button.js';

import picture from '../../images/aboutImages/churchKids.jpg';

function MeetSkyyMyles() {
  const [gridSizeXS, setGridSizeXS] = useState(false);

  useEffect(() => {
    
    // Define your custom breakpoints here
    if (window.innerWidth < 769) {
      setGridSizeXS(true)
    } 

  }, []);

  return (
    <Box
      component="section"
      sx={{ display: 'flex', overflow: 'hidden', bgcolor: '#F8F3ED', mb: 0 }}
    >
    <Container component="section" >
      <Grid container>
        {/* Larger Picture on the Left Side */}
      
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Box
            component="img"
            src={`${process.env.PUBLIC_URL}/images/aboutImages/churchKids.jpg`}
            sx={{
              width: {xs: '100%', sm: '100%', md: '100%', lg: '100%'},
              height: {xs: '100%', sm: '100%', md: '100%', lg:'100%'},
              objectFit: 'cover',
              position: 'relative', // Set position to relative
              right: '10%', // Move the image to the right by 50%
              ml: {xs: 5, sm: 5, md:6, lg:0}, 
              py: {xs: 3, sm: 5, md: 5, lg: 5},
             
            }}
          />
        </Grid>
      
        {/* Right-side Container */}
        <Grid item xs={12} sm={6} md={6} lg={6} sx={{ position: 'relative' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              padding: {xs:'1rem', sm:'2rem'},
              //position: gridSizeXS ? '' : 'absolute',
              //alignItems: 'center', // Center text horizontally
              //top: '50%', // Adjust vertical alignment
              //transform: gridSizeXS ? '' : 'translateY(-50%)', // Adjust vertical alignment
              zIndex: 1,
            }}
          >
            <Typography 
              variant="h1" 
              component="h2"  
              gutterBottom 
              sx={{ 
                color: 'black', 
                textDecoration: 'underline',
                fontSize: {
                  xs: '3rem',
                  sm: '2.5rem',
                  md: '3rem',
                  lg: '4rem',
                }, 
                textAlign: 'center',
              }}
            >
              Our Youth
            </Typography>
            <Typography variant="subtitle1" 
              sx={{ 
                my: 1, 
                fontStyle: 'italic',
                fontSize: {
                  xs: '1.25rem',
                  sm: '1.1rem',
                  md: '1.25rem',
                  lg: '1.5rem',
                }, 
              }} 
              align="center" 
            >
               "Start children off on the way they should go"
            </Typography>

            <Typography variant="subtitle1" 
              sx={{ 
                my: 1,
                fontSize: {
                  xs: '1.25rem',
                  sm: '1.1rem',
                  md: '1.25rem',
                  lg: '1.5rem',
                }, 
              }}
            >
              The purpose of the <span style={{ fontWeight: 'bold' }}>MAX-Q Youth Ministry</span> is to grow youth in their knowledge of and relationship with Jesus Christ. 
              By using sound biblical teaching principles and discipleship, the ministry seeks to excite, inspire, capture and ignite young people for Jesus Christ. 
            </Typography>

            <Typography variant="subtitle1" 
              sx={{ 
                my: 1,
                fontSize: {
                  xs: '1.25rem',
                  sm: '1.1rem',
                  md: '1.25rem',
                  lg: '1.5rem',
                }, 
              }}
            >
                            It is our goal to connect with and engage young people in an appropriate way, 
              so they understand the relevancy of God today and enable them to become tomorrow's Christian leaders.
            </Typography>
            
          </Box>
        </Grid>
      </Grid>
    </Container>
    </Box>
  );
}

export default MeetSkyyMyles;
