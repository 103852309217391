import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
//import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { createTheme, ThemeProvider } from '@mui/material';

const theme_break = createTheme({
    breakpoints: {
      values: {
        xs: 0,       // default breakpoint
        sm: 600,     // default breakpoint
        md: 960,     // default breakpoint
        lg: 1280,    // default breakpoint
        xl: 1920,    // default breakpoint
        mobile: 0, //Mobile
        tablet: 426,
        laptop: 769,
        laptopL: 1025, 
      },
    },
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ThemeProvider theme={theme_break}>
      <App />
    </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
