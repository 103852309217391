import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../../components/Typography.js';
import Button from '../../components/Button.js';

import picture from '../../images/mainPageImages/toni.jpg';

function MeetSkyyMyles() {
  const [gridSizeXS, setGridSizeXS] = useState(false);

  useEffect(() => {
    
    // Define your custom breakpoints here
    if (window.innerWidth < 769) {
      setGridSizeXS(true)
    } 

  }, []);

  return (
    <Box
      component="section"
      sx={{ display: 'flex', overflow: 'hidden', bgcolor: '#F8F3ED', mb: 0 }}
    >
    <Container component="section" >
      <Grid container>
        {/* Larger Picture on the Left Side */}
      
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Box
            component="img"
            src={`${process.env.PUBLIC_URL}/images/mainPageImages/toni.jpg`}
            sx={{
              width: {xs: '100%', sm: '100%', md: '100%', lg: '100%'},
              height: {xs: 'auto', sm: 'auto', md: 'auto', lg:'auto'},
              objectFit: 'cover',
              position: 'relative', // Set position to relative
              right: {xs: '0%', sm: '10%'}, // Move the image to the right by 50%
              //mr: 5,
              ml: {sm: 5, md:6, lg:0}, 
              py: {sm: 5, md: 5, lg: 5},
              pt: {xs: 5, sm: 0},
            }}
          />
        </Grid>
      
        {/* Right-side Container */}
        <Grid item xs={12} sm={6} md={6} lg={6} sx={{ position: 'relative' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              //alignItems: 'center',
              padding: '1rem',
              zIndex: 1,
              pt: 5,
            }}
          >
            <Typography 
              variant="h1" 
              component="h2"  
              gutterBottom 
              sx={{ 
                color: 'black', 
                textDecoration: 'underline',
                fontSize: {
                  xs: '3rem',
                  sm: '2.5rem',
                  md: '3rem',
                  lg: '3rem',
                }, 
                textAlign: 'center',
              }}
            >
              OUR FIRST LADY
            </Typography>
            <Typography variant="subtitle1" 
              sx={{ 
                //my: 1, 
                fontStyle: 'italic',
                fontSize: {
                  xs: '1.25rem',
                  sm: '1.1rem',
                  md: '1.1rem',
                  lg: '1.1rem',
                }, 
              }} 
              align="center" 
            >
               "For the Priests lips should keep knowledge; for he is the messenger of the Lord of Hosts"
            </Typography>
            <Typography variant="subtitle1" 
              sx={{ 
                //my: 1, 
                fontStyle: 'italic',
                fontSize: {
                  xs: '1.25rem',
                  sm: '1.1rem',
                  md: '1.1rem',
                  lg: '1.1rem',
                }, 
                textAlign: 'right'
              }} 
              
            >
               - Malachi 2:7
            </Typography>

            <Typography variant="subtitle1" 
              sx={{ 
                my: 1,
                fontSize: {
                  xs: '1.25rem',
                  sm: '1.1rem',
                  md: '1.25rem',
                  lg: '1.5rem',
                }, 
              }}
            >
              With a deep passion for God’s Word and a heart for God’s people, 
              <span style={{ fontWeight: 'bold' }}> First Lady Antonia Davis</span> brings a spirit of generosity and dedication to every part of her ministry at Nazarene Baptist Church. 
              She embraces the calling to inspire transformation through God’s love, 
              faithfully guiding others toward Christ’s hope and deliverance.
            </Typography>
            
          </Box>
        </Grid>
      </Grid>
    </Container>
    </Box>
  );
}

export default MeetSkyyMyles;
