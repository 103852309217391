import * as React from 'react';
import withRoot from '../withRoot';

import AboutHero from './AboutHero.js';
import OurPastor from './OurPastorUpdated.js';
import PastorP2 from './PastorP2.js';


function Index() {
  return (
    <React.Fragment>
      <AboutHero/> 
      <OurPastor/>
      <PastorP2/>
    </React.Fragment>
  );
}

export default withRoot(Index);
