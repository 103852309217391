import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../../components/Typography.js';
import Button from '../../components/Button.js';

import picture from '../../images/aboutImages/chruchPinning.jpg';

function MeetSkyyMyles() {
  const [gridSizeXS, setGridSizeXS] = useState(false);

  useEffect(() => {
    
    // Define your custom breakpoints here
    if (window.innerWidth < 769) {
      setGridSizeXS(true)
    } 

  }, []);

  return (
    <Box
      component="section"
      sx={{ display: 'flex', overflow: 'hidden', bgcolor: '#F8F3ED', mb: 0 }}
    >
    <Container component="section" >
      <Grid container spacing={5} sx={{py: 5}}>
        {/* Larger Picture on the Left Side */}
      
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Box
            component="img"
            src={`${process.env.PUBLIC_URL}/images/aboutImages/chruchPinning.jpg`}
            sx={{
              width: {xs: '100%', sm: '100%', md: '100%', lg: '100%'},
              height: {xs: 'auto', sm: 'auto', md: 'auto', lg:'auto'},
              objectFit: 'cover',
              position: 'relative', // Set position to relative
              right: {xs: '0%', sm: '10%'}, // Move the image to the right by 50%
              ml: {sm: 5, md:6, lg:0}, 
              py: {sm: 5, md: 5, lg: 5},
              pt: {xs: 5, sm: 0},
            }}
          />
        </Grid>
      
        {/* Right-side Container */}
        <Grid item xs={12} sm={6} md={6} lg={6} sx={{ position: 'relative' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              padding: {xs: '0rem', sm: '2rem'},
              zIndex: 1,
            }}
          >
            <Typography 
              variant="h3" 
              component="h2"  
              gutterBottom 
              sx={{ 
                color: 'black', 
                textDecoration: 'underline',
                fontSize: {
                  xs: '2rem',
                  sm: '2.2rem',
                  md: '2.25rem',
                  lg: '2.5rem',
                }, 
                textAlign: 'center',
                fontFamily: 'Poppins, sans-serif',
                mt: {xs:0, sm: 5},
              }}
            >
              Values To Your Core
            </Typography>

            <Typography variant="subtitle1" 
              sx={{ 
                my: 1,
                fontSize: {
                  xs: '1.25rem',
                  sm: '1.1rem',
                  md: '1.25rem',
                  lg: '1.5rem',
                }, 
                textAlign: 'center',
                mb: {xs:5, sm: 0},
              }}
            >
              The Nazarene Baptist Church has made a commitment to always put God first. 
              That basic premise guides us in everything that we do, which is always striving to SEE HIM, at all times.
            </Typography>

            
          </Box>
        </Grid>
      </Grid>
    </Container>
    </Box>
  );
}

export default MeetSkyyMyles;
