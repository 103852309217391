import * as React from 'react';
import Button from '../../components/Button.js';
import Typography from '../../components/Typography.js';
import AboutHeroLayout from './AboutHeroLayout.js';
import backgroundImage from '../../images/background/churchTop.jpg';

export default function ProductHero() {
  return (
    <AboutHeroLayout
      sxBackground={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/background/churchTop.jpg)`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    />

  );
}
