import * as React from 'react';
import withRoot from '../withRoot.js';

import AboutHero from './AboutHero.js';
import OurPastor from './OurPastorUpdated.js';

function Index() {
  return (
    <React.Fragment>
      <AboutHero/> 
      <OurPastor/>
    </React.Fragment>
  );
}

export default withRoot(Index);
