import React, { useEffect } from 'react';
import withRoot from './withRoot';

import ContactHero from './ContactHero';
import MapTextRight from './MapTextRight';

function Index() {

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <ContactHero/>
      <MapTextRight/>
    </React.Fragment>
  );
}

export default withRoot(Index);
