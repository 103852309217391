import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../../components/Typography.js';
import Button from '../../components/Button.js';

//import conservatory from '../images/aboutImages/conservatory.jpg';
import picture from '../../images/background/churchDance3.jpg';

function MeetSkyyMyles() {
  const [gridSizeXS, setGridSizeXS] = useState(false);

  useEffect(() => {
    
    // Define your custom breakpoints here
    if (window.innerWidth < 769) {
      setGridSizeXS(true)
    } 

  }, []);

  return (
    <Box
      component="section"
      sx={{ display: 'flex', overflow: 'hidden', bgcolor: '#F8F3ED', mb: 0 }}
    >
    <Container component="section" >
      <Grid container>
        {/* Larger Picture on the Left Side */}
      
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Box
            component="img"
            src={`${process.env.PUBLIC_URL}/images/background/churchDance3.jpg`}
            sx={{
              width: {xs: '0%', sm: '100%', md: '100%', lg: '100%'},
              height: {xs: '0%', sm: '100%', md: '100%', lg:'100%'},
              objectFit: 'cover',
              position: 'relative', // Set position to relative
              right: '10%', // Move the image to the right by 50%
              ml: {sm: 5, md:6, lg:0}, 
              py: {sm: 5, md: 5, lg: 5}
            }}
          />
        </Grid>
      
        {/* Right-side Container */}
        <Grid item xs={12} sm={6} md={6} lg={6} sx={{ position: 'relative' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              padding: '2rem',
              //position: gridSizeXS ? '' : 'absolute',
              alignItems: 'center', // Center text horizontally
              //top: '50%', // Adjust vertical alignment
              //transform: gridSizeXS ? '' : 'translateY(-50%)', // Adjust vertical alignment
              zIndex: 1,
            }}
          >
            <Typography 
              variant="h1" 
              component="h2"  
              gutterBottom 
              sx={{ 
                color: 'black', 
                textDecoration: 'underline',
                fontSize: {
                  xs: '3rem',
                  sm: '2.5rem',
                  md: '3rem',
                  lg: '4rem',
                }, 
                textAlign: 'center',
              }}
            >
              Our Vision
            </Typography>
            <Typography variant="subtitle1" 
              sx={{ 
                my: 1, 
                fontStyle: 'italic',
                fontSize: {
                  xs: '1.25rem',
                  sm: '1.1rem',
                  md: '1.25rem',
                  lg: '1.5rem',
                }, 
              }} 
              align="center" 
            >
              "A Church growing and seeking to reach her potential to the Glory of God"
            </Typography>
            <Typography variant="subtitle1" 
              sx={{ 
                my: 1,
                fontSize: {
                  xs: '1.25rem',
                  sm: '1.1rem',
                  md: '1.25rem',
                  lg: '1.5rem',
                }, 
              }}
            >
              <span style={{ fontWeight: 'bold' }}>The Nazarene Baptist Church</span> is a fellowship of believers that purpose to know God through a personal relationship with Jesus Christ that equips, educates and empowers people to fulfill their God given destiny and to reach their maximum potential.
            </Typography>
            <Typography variant="subtitle1" 
              sx={{ 
                my: 1,
                fontSize: {
                  xs: '1.25rem',
                  sm: '1.1rem',
                  md: '1.25rem',
                  lg: '1.5rem',
                }, 
              }}
            >
            <span style={{ fontWeight: 'bold' }}>Our Mission</span> is to transform lives one step at a time through discipleship, spirit-filled teaching, prayer, praise, worship, and fellowship.  We seek to help the believer to grow in the grace and knowledge of Jesus Christ that they may know and do His will.
            </Typography>
            
          </Box>
        </Grid>
      </Grid>
    </Container>
    </Box>
  );
}

export default MeetSkyyMyles;
