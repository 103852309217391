
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
//import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import Typography from './components/Typography.js';
import TextField from './components/TextField.js';
import IconButton from '@mui/material/IconButton';

//Icons
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import EmailIcon from '@mui/icons-material/Email';
import YouTubeIcon from '@mui/icons-material/YouTube';

function Copyright() {
  return (
    <React.Fragment>
      <Typography variant="h6" sx={{ color: 'white', fontWeight: 300, fontSize: {mobile: '3vw', tablet: '2.75vw', laptop: '1vw'}}}>
        {'© '} Nazarene Baptist Church {' '} {new Date().getFullYear()}
      </Typography>
    </React.Fragment>
  );
}

const iconStyle = {
  width: 48,
  height: 48,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'warning.main',
  mr: 1,
  '&:hover': {
    bgcolor: 'warning.dark',
  },
};

const CenteredContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
}));

export default function AppFooter() {
  const [gridSizeSX, setGridSizeSX] = useState(false);

  useEffect(() => {
    const width = window.innerWidth;    
    // Define your custom breakpoints here
    if (width < 600) {
      setGridSizeSX(true);
    } 
  }, []); 

  return (
    <Box
      component="footer"
      sx={{ 
        display: 'flex', 
        bgcolor: '#000000', 
        color: 'white',  
        alignItems: 'center',
        textAlign: 'center',
        px: 5,
        py: 7,
      }}
    >
      
        <Grid container spacing={5} justifyContent="center">
          <Grid item sx={12} tablet={12} laptop={4}>
            <Grid container direction="column" spacing={1} alignItems="center" >
                <Grid item>
                  <Typography 
                    sx={{ 
                      color: 'white',  
                      borderBottom: '2px solid white', 
                      fontWeight: 400, 
                      fontSize: {
                        mobile: '8vw', 
                        tablet: '6.5vw', 
                        laptop: '2.5vw'
                      },
                      fontFamily: 'Pattaya'
                    }}>
                    Nazarene Baptist
                  </Typography>
                </Grid>
              
              <Grid item>
                <Copyright />
              </Grid>
              <Grid item>
                <Typography variant="caption" sx={{ color: 'white', fontWeight: "bold", 
                  fontSize: {mobile: '3vw', tablet: '2.25vw', laptop: '1vw'}, fontFamily: 'Poppins, sans-serif',  }}>
                  {'Website made by '}   
                  <a href="https://mammothwebsites.com" target="_blank" rel="noopener noreferrer">
                    Mammoth Websites
                  </a>              
                </Typography>
              </Grid>
              
            </Grid>
          </Grid>

          <Grid item mobile={12} tablet={12} laptop={4}>
            <Grid container direction="column" alignItems="center" >
              <Grid item>
                    <Typography variant="h6" sx={{ color: 'white' ,  borderBottom: '2px solid white', fontWeight: 600, fontSize: {mobile: '6.5vw', tablet: '5vw', laptop: '2vw'}, fontFamily: 'Merriweather', }}>
                      Contact Us
                    </Typography>
              </Grid>
              <Grid item >
                    <Typography variant="h6" sx={{ color: 'white', mt: 2, fontWeight: 400, fontSize: {mobile: '4vw', tablet: '3vw', laptop: '1.5vw'},  fontFamily: 'Poppins, sans-serif',}}>
                      Phone: (410) 385 - 1986
                    </Typography>
                    <Typography variant="h6" sx={{ color: 'white', mb: 1, fontWeight: 400, fontSize: {mobile: '4vw', tablet: '3vw', laptop: '1.5vw'},  fontFamily: 'Poppins, sans-serif',}}>
                      Email: info@nazarenebaptist.org
                    </Typography>
              </Grid>
              <Grid item>
                <IconButton color="inherit" href="https://www.instagram.com/nazarenebaptist/" target="_blank" >
                  <InstagramIcon sx={{ fontSize: {mobile: '7vw', tablet: '5vw', laptop: '2.5vw'} }}/>
                </IconButton>

                <IconButton color="inherit" href="https://www.facebook.com/THENBCMINISTRIES/" target="_blank">
                  <FacebookIcon sx={{ fontSize: {mobile: '7vw', tablet: '5vw', laptop: '2.5vw'}}}/>
                </IconButton>

                <IconButton color="inherit" href="https://www.youtube.com/@nazarenebaptistchurch4671" target="_blank">
                  <YouTubeIcon sx={{ fontSize: {mobile: '7vw', tablet: '5vw', laptop: '2.5vw'}}} />
                </IconButton>

                <IconButton color="inherit" href="mailto:info@nazarenebaptist.org" target="_blank">
                  <EmailIcon sx={{ fontSize: {mobile: '7vw', tablet: '5vw', laptop: '2.5vw'}}}/>
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          
          <Grid item mobile={12} tablet={12} laptop={4}>
            <Grid container direction="column" spacing={1} justifyContent={gridSizeSX ? "center" : "flex-start"} alignItems="center" >
              <Box component="ul" sx={{ m: 0, listStyle: 'none', p: 0 }}>
                <Typography variant="h6" gutterBottom sx={{ color: 'white', borderBottom: '2px solid white', 
                    fontWeight: 600, fontSize: {mobile: '6.5vw', tablet: '5vw', laptop: '2vw'}, fontFamily: 'Merriweather', }}>
                  Pages
                </Typography>
                <Box component="li" sx={{ py: 0.5 }}>
                  <Link component={Link} to={"/our-vision"} sx={{ color: 'white',  }}>
                    <Typography variant="h6" sx={{ color: 'white', fontWeight: 400, fontSize: {mobile: '4vw', tablet: '3vw', laptop: '2vw', laptopL: '1.5vw'},  fontFamily: 'Poppins, sans-serif',}}>
                    About Us
                    </Typography>
                  </Link>
                </Box>
                <Box component="li" sx={{ py: 0.5 }} >
                  <Link component={Link} to={"/giving"} sx={{ color: 'white' }}>
                    <Typography variant="h6" sx={{ color: 'white', fontWeight: 400, fontSize: {mobile: '4vw', tablet: '3vw', laptop: '2vw', laptopL: '1.5vw'},  fontFamily: 'Poppins, sans-serif',}}>
                    Giving
                    </Typography>
                  </Link>
                </Box>
                <Box component="li" sx={{ py: 0.5 }}>
                  <Link component={Link} to={"/contact"} sx={{ color: 'white' }}>
                    <Typography variant="h6" sx={{ color: 'white', fontWeight: 400, fontSize: {mobile: '4vw', tablet: '3vw', laptop: '2vw', laptopL: '1.5vw'},  fontFamily: 'Poppins, sans-serif',}}>
                    Contact
                    </Typography>
                  </Link>
                </Box>
              </Box>
            </Grid>
          </Grid>
          
          
        </Grid>
      </Box>
  );
}
