import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../../components/Typography.js';
import Button from '../../components/Button.js';

import picture from '../../images/pastorImages/pastorVert.jpg';

function MeetSkyyMyles() {
  const [gridSizeXS, setGridSizeXS] = useState(false);

  useEffect(() => {
    
    // Define your custom breakpoints here
    if (window.innerWidth < 769) {
      setGridSizeXS(true)
    } 

  }, []);

  return (
    <Box
      component="section"
      sx={{ display: 'flex', overflow: 'hidden', bgcolor: '#F8F3ED', mb: 0 }}
    >
    <Container component="section" >
      <Grid container>
        {/* Larger Picture on the Left Side */}
      
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Box
            component="img"
            src={`${process.env.PUBLIC_URL}/images/pastorImages/pastorVert.jpg`}
            sx={{
              width: {xs: '100%', sm: '100%', md: '100%', lg: '100%'},
              height: {xs: 'auto', sm: 'auto', md: 'auto', lg:'auto'},
              objectFit: 'cover',
              position: 'relative', // Set position to relative
              right: {xs: '0%', sm: '10%'}, // Move the image to the right by 50%
              ml: {sm: 5, md:6, lg:0}, 
              py: {sm: 5, md: 5, lg: 5},
              pt: {xs: 5, sm: 0},
            }}
          />
        </Grid>
      
        {/* Right-side Container */}
        <Grid item xs={12} sm={6} md={6} lg={6} sx={{ position: 'relative' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              padding: '2rem',
              //position: gridSizeXS ? '' : 'absolute',
              //alignItems: 'center', // Center text horizontally
              //top: '50%', // Adjust vertical alignment
              //transform: gridSizeXS ? '' : 'translateY(-50%)', // Adjust vertical alignment
              zIndex: 1,
            }}
          >
            <Typography 
              variant="h1" 
              component="h2"  
              gutterBottom 
              sx={{ 
                color: 'black', 
                textDecoration: 'underline',
                fontSize: {
                  xs: '3rem',
                  sm: '2.5rem',
                  md: '3rem',
                  lg: '4rem',
                }, 
                textAlign: 'center',
              }}
            >
              Our Pastor
            </Typography>
            <Typography variant="subtitle1" 
              sx={{ 
                my: 1, 
                fontStyle: 'italic',
                fontSize: {
                  xs: '1.25rem',
                  sm: '1.1rem',
                  md: '1.25rem',
                  lg: '1.5rem',
                }, 
              }} 
              align="center" 
            >
               "For the Priests lips should keep knowledge; for he is the messenger of the Lord of Hosts"
            </Typography>
            <Typography variant="subtitle1" 
              sx={{ 
                my: 1, 
                fontStyle: 'italic',
                fontSize: {
                  xs: '1.25rem',
                  sm: '1.1rem',
                  md: '1.25rem',
                  lg: '1.5rem',
                }, 
                textAlign: 'right'
              }} 
              
            >
               - Malachi 2:7
            </Typography>

            <Typography variant="subtitle1" 
              sx={{ 
                my: 1,
                fontSize: {
                  xs: '1.25rem',
                  sm: '1.1rem',
                  md: '1.25rem',
                  lg: '1.5rem',
                }, 
              }}
            >
              <span style={{ fontWeight: 'bold' }}>Pastor Alphonzo Davis</span> has a tremendous passion for God's Word coupled with a love for God's people. He has a contagious spirit of generosity that flows through every facet of ministry. Since receiving his mandate, Pastor Davis is obediently following his calling as Pastor of The Nazarene Baptist Church.
            </Typography>

            <Typography variant="subtitle1" 
              sx={{ 
                my: 1,
                fontSize: {
                  xs: '1.25rem',
                  sm: '1.1rem',
                  md: '1.25rem',
                  lg: '1.5rem',
                }, 
              }}
            >
              He is excited about what the Lord has done, is doing and what He will do to and through the Nazarene Baptist Church.
              Nothing is more influential in <span style={{ fontWeight: 'bold' }}> shaping and molding our world </span> than a yielded vessel to carry the message of Christ's hope and deliverance for all. 
              These attributes are found in Pastor Davis.
            </Typography>


            <Typography variant="subtitle1" 
              sx={{ 
                my: 1,
                fontSize: {
                  xs: '1.25rem',
                  sm: '1.1rem',
                  md: '1.25rem',
                  lg: '1.5rem',
                }, 
              }}
            >
            <span style={{ fontWeight: 'bold' }}>He has been chosen to usher in a powerful move of God. </span> 
            is not afraid of the assignment God has put in his hands to accomplish. His burning vision to see the lives of all mankind transformed by the revelation of God's Word is evident in his commitment to ministry and his lifestyle of holiness.
            </Typography>
            
          </Box>
        </Grid>
      </Grid>
    </Container>
    </Box>
  );
}

export default MeetSkyyMyles;
