import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`action-tabpanel-${index}`}
      aria-labelledby={`action-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `action-tab-${index}`,
    'aria-controls': `action-tabpanel-${index}`,
  };
}

export default function FloatingActionButtonZoom() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [gridSizeXS, setGridSizeXS] = useState(false);

  //Dialog
  const [open, setOpen] = React.useState(false);
  const [openBaptism, setOpenBaptism] = React.useState(false);
  const [openEducation, setOpenEducation] = React.useState(false);

  useEffect(() => {
    
    // Define your custom breakpoints here
    if (window.innerWidth < 769) {
      setGridSizeXS(true)
    } 

  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

 
  //Dialog
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickOpenBaptism = () => {
    setOpenBaptism(true);
  };
  const handleClickOpenEducation = () => {
    setOpenEducation(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenBaptism(false);
    setOpenEducation(false)
  };


  return (
    <Box
      sx={{
        bgcolor: 'black',
        color: 'white',
        width: 'auto',
        position: 'relative',
        minHeight: 200,
        pt: 5,
        //mb: -2, // Adjust margin bottom to cover AppBar's bottom border
      }}
    >
      <AppBar position="static" sx={{ bgcolor: 'black', color: 'white', mb: 2 }}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="inherit"
          variant="fullWidth"
        >
          <Tab label="New Members" {...a11yProps(0)} sx={{ fontSize: {xs: '1.2rem', sm: '1.5rem'} }}/>
          <Tab label="Baptism" {...a11yProps(1)} sx={{ fontSize: {xs: '1.2rem', sm: '1.5rem'} }}/>
          <Tab label="Education" {...a11yProps(2)} sx={{ fontSize: {xs: '1.2rem', sm: '1.5rem'} }}/>
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
            <Container component="section" sx={{my:2}}>
                <Grid container>
                    
                    <Grid item xs={12} md={1}></Grid>
                    <Grid item xs={12} md={5}>
                        <Typography variant="h5" sx={{ color: 'white', fontSize: '4rem'}}>
                            New
                        </Typography>
                        <Typography variant="h5" sx={{ color: 'white', fontSize: '4rem'}}>
                            Members
                        </Typography>
                    </Grid>
        
                    <Grid item xs={12} md={6} >
                        <Typography variant="h1" sx={{ color: 'yellow', fontSize: '2.5rem', fontFamily: 'Poppins, sans-serif'}}>
                            A PLACE TO BELONG
                        </Typography>
                        <Typography variant="h5" sx={{ color: 'white', fontSize: '1.4rem', mt: 2}}>
                            The New Member Team serves as a ministry link to connect God's people back to Him through His Son Jesus Christ. 
                        </Typography>
                        <Typography variant="h5" sx={{ color: 'white', fontSize: '1.4rem', mt:2}}>                            
                            Our goal is to not only help you understand who we are and what we believe but also to fully understand who you are and why God made you uniquely you.
                        </Typography>
                        <Button 
                            variant="contained"
                            sx={{
                                mt: 4,
                                fontSize: '1.2rem',
                                backgroundColor: 'white', // Set background color to white
                                color: 'black', // Set text color to black
                            }}
                            onClick={handleClickOpen}
                        >
                            <Typography variant="b1" sx={{fontSize: '1.2rem', mx: 1}}>
                                Learn More
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
            <Container component="section" sx={{my:2}}>
                <Grid container>
                    
                    <Grid item xs={12} md={1}></Grid>
                    <Grid item xs={12} md={5}>
                        <Typography variant="h5" sx={{ color: 'white', fontSize: '4rem'}}>
                            Baptism
                        </Typography>
                    </Grid>
        
                    <Grid item xs={12} md={6} >
                        <Typography variant="h1" sx={{ color: 'yellow', fontSize: '2.5rem', fontFamily: 'Poppins, sans-serif'}}>
                            Take The Leap
                        </Typography>
                        <Typography variant="h5" sx={{ color: 'white', fontSize: '1.4rem', mt: 2}}>
                            There is nothing special about the water ...
                        </Typography>
                        <Typography variant="h5" sx={{ color: 'white', fontSize: '1.4rem', mt:2}}>                            
                            Baptism is an essential step of obedience that shows others we have personally trusted Jesus for our salvation.
                        </Typography>
                        <Typography variant="h5" sx={{ color: 'white', fontSize: '1.4rem', mt:2}}>                            
                            Water baptism is a public declaration of three important things: 
                            you are a follower of Jesus Christ, beginning a changed life in Christ, 
                            and part of a new family.
                        </Typography>
                        <Button 
                            variant="contained"
                            sx={{
                                mt: 4,
                                fontSize: '1.2rem',
                                backgroundColor: 'white', // Set background color to white
                                color: 'black', // Set text color to black
                            }}
                            onClick={handleClickOpenBaptism}
                        >
                            <Typography variant="b1" sx={{fontSize: '1.2rem', mx: 1}}>
                                Learn More
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
        <Container component="section" sx={{my:2}}>
                <Grid container>
                    <Grid item xs={12} md={1}></Grid>
                    <Grid item xs={12} md={5}>
                        <Typography variant="h5" sx={{ color: 'white', fontSize: '4rem'}}>
                            Education
                        </Typography>
                    </Grid>
        
                    <Grid item xs={12} md={6} >
                        <Typography variant="h1" sx={{ color: 'yellow', fontSize: '2.5rem', fontFamily: 'Poppins, sans-serif'}}>
                            Discover your purpose
                        </Typography>
                        <Typography variant="h5" sx={{ color: 'white', fontSize: '1.4rem', mt: 2}}>
                            We want to set you up for success by providing you with training and materials to help you move another step
                        </Typography>
                        <Typography variant="h5" sx={{ color: 'white', fontSize: '1.4rem', mt:2}}>                            
                            We provide classes, workshops, and seminars for spiritual growth for all ages.
                        </Typography>
                        <Typography variant="h5" sx={{ color: 'white', fontSize: '1.4rem', mt:2}}>                            
                            Transform your character and values into Kingdom attributes
                        </Typography>
                        <Button 
                            variant="contained"
                            sx={{
                                mt: 4,
                                fontSize: '1.2rem',
                                backgroundColor: 'white', // Set background color to white
                                color: 'black', // Set text color to black
                            }}
                            onClick={handleClickOpenEducation}
                        >
                            <Typography variant="b1" sx={{fontSize: '1.2rem', mx: 1}}>
                                Learn More
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </TabPanel>
      </SwipeableViews>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >

        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Typography variant="h2" align="center" sx={{ color: 'black', fontSize: '2.5rem', fontFamily: 'Poppins, sans-serif', mb: 2 }}>
                New Members
            </Typography>
            <Typography variant="h4" align="center" sx={{ color: 'black', fontSize: '1.4rem', fontFamily: 'Poppins, sans-serif', mb: 3}}>
                Now that you are here, we invite you to connect with the family! 
            </Typography>
            <Typography variant="body1" sx={{ color: 'black', fontSize: '1.2rem', mt: 2}}>
                The New Member Team serves as a ministry link to connect God's people back to Him through His Son Jesus Christ. 
                We also strive to connect newcomers to The Kingdom of God and to the Nazarene Baptist Church Family. 
            </Typography>
            <Typography variant="h5" sx={{ color: 'black', fontSize: '1.2rem', mt: 2}}>
                This department points every member to their next steps. (Orientation, Baptism and Baby Dedication) 
            </Typography>
            
            <Typography variant="h5" sx={{ color: 'black', fontSize: '1.2rem', mt: 2}}>
                Our goal is to not only help you understand who we are and what we believe but also to fully understand who you are and why God made you uniquely you.
            </Typography>
          
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openBaptism}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >

        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Typography variant="h2" align="center" sx={{ color: 'black', fontSize: '2.5rem', fontFamily: 'Poppins, sans-serif', mb: 2 }}>
                Baptism
            </Typography>
            <Typography variant="h4" align="center" sx={{ color: 'black', fontSize: '1.4rem', fontFamily: 'Poppins, sans-serif', mb: 3}}>
                Be washed clean in the waters! 
            </Typography>
            <Typography variant="h5" sx={{ color: 'black', fontSize: '1.2rem', mt: 2}}>
            Baptism is a Christian ordinance that symbolizes the death of the old nature of sin (our carnal selves) and a resurrection to a new life in Christ Jesus (Romans 6:3-8). 
            It also symbolizes the cleansing power of His blood and an out-ward testimony of our faith in the Lord Jesus.
            </Typography>
            <Typography variant="body1" sx={{ color: 'black', fontSize: '1.2rem', mt: 2}}>
                Baptismal services are held on the third (3rd) Sunday (Feb, April, June, October, December)
            </Typography>
            <Typography variant="h5" sx={{ color: 'black', fontSize: '1.2rem', mt: 2}}>
                Please email your request tobaptism@nazarenebaptist.org or 410-385-1996
            </Typography>
          
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openEducation}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >

        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Typography variant="h2" align="center" sx={{ color: 'black', fontSize: '2.5rem', fontFamily: 'Poppins, sans-serif', mb: 2 }}>
                CHRISTIAN EDUCATION
            </Typography>
            <Typography variant="h4" align="center" sx={{ color: 'black', fontSize: '1.4rem', fontFamily: 'Poppins, sans-serif', mb: 3}}>
                Study to show thyself approved unto God.
            </Typography>
            <Typography variant="h5" sx={{ color: 'black', fontSize: '1.2rem', mt: 2}}>
            At Nazarene Baptist Church, our Christian Education program offers a diverse range of classes, 
            workshops, and seminars tailored for all ages, fostering spiritual growth and development.
            </Typography>
            <Typography variant="body1" sx={{ color: 'black', fontSize: '1.2rem', mt: 2}}>
            Our Saturday and Sunday School programs are designed to cultivate a personal, lifelong journey of faith with Jesus Christ. 
            </Typography>
            <Typography variant="h5" sx={{ color: 'black', fontSize: '1.2rem', mt: 2}}>
            Through these sessions, we aim to nurture a transformative relationship where Christ shapes your character, 
            instills Kingdom values, and empowers you to participate in His mission within your home, our community, and the wider world."
            </Typography>
          
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
