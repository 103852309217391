import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { styled } from '@mui/material/styles';

// My Components
import AppBar from './components/AppBar';

// MUI Components
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Grid from '@mui/material/Grid';
import Drawer from '@mui/material/Drawer';
import Hidden from '@mui/material/Hidden'; 
import Collapse from '@mui/material/Collapse';

// Icons
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import EmailIcon from '@mui/icons-material/Email';
import YouTubeIcon from '@mui/icons-material/YouTube';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AdbIcon from '@mui/icons-material/Adb';
import MenuIcon from '@mui/icons-material/Menu';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';


// Logo
//import logo from './images/logos/chruchLogo-removebg.png';

function MobileAppBar() {
  const [scrolling, setScrolling] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [openPropDrawer, setOpenPropDrawer] = React.useState(false);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo(0, 0)
  }
  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const handlePropDrawerClick = () => {
    setOpenPropDrawer(!openPropDrawer);
  };

 
  const drawerContent = (
    <List>
     

      <ListItemButton onClick={handlePropDrawerClick}>
        <ListItemText primary="About" />
        {openPropDrawer ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openPropDrawer} timeout="auto" unmountOnExit sx={{ml: 2}}>
        <List component="div" disablePadding>
          <ListItemButton component={Link} to={"/our-vision"} onClick={scrollToTop}>
            <ListItemText primary="Our Vision" />
          </ListItemButton>
          <ListItemButton component={Link} to={"/our-pastor"} onClick={scrollToTop}>
            <ListItemText primary="Our Pastor" />
          </ListItemButton>
          <ListItemButton component={Link} to={"/our-first-lady"} onClick={scrollToTop}>
            <ListItemText primary="Our First Lady" />
          </ListItemButton>
          <ListItemButton component={Link} to={"/our-youth"} onClick={scrollToTop}>
            <ListItemText primary="Our Youth" />
          </ListItemButton>
          <ListItemButton component={Link} to={"/our-beliefs"} onClick={scrollToTop}>
            <ListItemText primary="Our Beliefs" />
          </ListItemButton>
          <ListItemButton component={Link} to={"/our-history"} onClick={scrollToTop}>
            <ListItemText primary="Our History" />
          </ListItemButton>
          <ListItemButton component={Link} to={"/our-ministries"} onClick={scrollToTop}>
            <ListItemText primary="Our Ministries" />
          </ListItemButton>
        </List>
      </Collapse>

      <ListItemButton component={Link} to={'/giving'} onClick={scrollToTop}>
        <ListItemText primary="Giving" />
      </ListItemButton>

      <ListItemButton component={Link} to={'/contact'} onClick={scrollToTop}>
        <ListItemText primary="Contact" />
      </ListItemButton>

    </List>
  );

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };  

  return (
    
    <Box sx={{ flexGrow: 1 }}>
      <AppBar 
        position="fixed"
        sx={{ 
          backgroundColor: scrolling ? 'rgba(15, 15, 15, 1)' : 'black', 
          transition: 'background-color 0.5s', 
          zIndex: 1000,
          //width: "80%",
          left: 0,
          p: 1
        }}
      >
  
        <Toolbar 
          disableGutters
          style={{
            height: '100%', // Added height to center vertically
            width: '100%',
          }}
        >
          <Grid container spacing={0}>
            <Grid xs={2}>
              <Box>
              <IconButton href="/">
                <Avatar src={`${process.env.PUBLIC_URL}/images/logos/chruchLogo-removebg.png`}
                  alt="Logo"
                  sx={{
                    width: { xs: '40px', sm: '50px', md: '50px', lg: '70px', xl: '80px'},
                    height: { xs: '40px', sm: '50px', md: '50px', lg: '70px', xl: '80px'},
                  }} />
              </IconButton>
              </Box>
            </Grid>

            <Grid xs={8}>
              <Box 
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                width="100%"
                height="100%"
                sx={{ flexGrow: 1, zIndex: 10 }}
              >
                <Typography
                    noWrap
                    component="div"
                    href="/"
                    sx={{
                      fontFamily: 'DM Serif Text',
                      fontWeight: 500,
                      letterSpacing: '.1rem',
                      color: 'white',
                      textDecoration: 'none',
                      fontSize: {xs: '5vw', sm: '5vw', md: '5vw', lg: '3.5vw'} //resize based on screensize
                    }}
                  >
                    Nazarene Baptist
                  </Typography>
                </Box>
            </Grid>

            <Grid xs={2}>
              <Box 
                display="flex"
                flexDirection="row"
                justifyContent="right"
                alignItems="center"
                width="100%"
                height="100%"
                sx={{ flexGrow: 1, zIndex: 10 }}
              >
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={toggleDrawer(true)}
                >
                  <MenuIcon />
                </IconButton>
              </Box>

              <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)} >
                <div style={{ width: 225 }}>
                  {drawerContent}
                </div>
              </Drawer>
            </Grid>
          </Grid>
              
             
             
              


          
        </Toolbar>
       
      </AppBar>
      
    </Box>
    
  );
}

export default MobileAppBar;