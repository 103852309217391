import * as React from 'react';
import withRoot from '../withRoot.js';

import AboutHero from './AboutHero.js';
import OurMinistry from './OurMinistryUpdated.js';
import FloatingActionButtonZoom from './Tabs.js';
import FourCards from './Cards.js';

function Index() {
  return (
    <React.Fragment>
      <AboutHero/> 
      <FloatingActionButtonZoom/>
      <OurMinistry/>
      <FourCards/>
    </React.Fragment>
  );
}

export default withRoot(Index);
