import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';

export default function TimelineDetails() {

 
  return (
    <Timeline position="alternate">
      <TimelineItem>
        <TimelineOppositeContent color="text.secondary" sx={{ fontSize: {xs:'1.2rem', md: '1.25rem', lg:'1.5rem'}}}>
         1874
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot style={{ backgroundColor: 'black' }} />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Typography variant="b1" sx={{ fontSize: {xs:'1.2rem', md: '1.5rem', lg:'1.75rem'}, fontFamily: 'Neue Montreal, sans-serif', }}>
              The Church formerly known as Harford Avenue Methodist Church Extension was built.
              The former parishioners moved, stripping the building, placing it up for sale or rent. 
              The original sale price was $10,000.00.
              Rev. Hall made arrangements to rent the building for the amount of $45.00 per month with the first option to buy.
              Before the first service could be held, major repairs and installations had to be made.
          </Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem >
        <TimelineOppositeContent color="text.secondary" sx={{ fontSize: {xs:'1.2rem', md: '1.25rem', lg:'1.5rem'}}}>
          1942
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot style={{ backgroundColor: 'black' }}/>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Typography variant="b1" sx={{ fontSize: {xs:'1.2rem', md: '1.5rem', lg:'1.75rem'}, fontFamily: 'Neue Montreal, sans-serif' }}>
          Nazarene Baptist Church, named in remembrance of Jesus of Nazareth, grew out of The First Baptist Church of Belair, Maryland on Sunday, May 3, 1942.
          This group was set apart with a roll call of eight members. 
          Nazarene Baptist Church was organized in May 1942 by the late Rev. William Ashley Hall.
          </Typography>
        </TimelineContent>
      </TimelineItem>

      <TimelineItem>
        <TimelineOppositeContent color="text.secondary" sx={{ fontSize: {xs:'1.2rem', md: '1.25rem', lg:'1.5rem'}}}>
          1944
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot style={{ backgroundColor: 'black' }} />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Typography variant="b1" sx={{ fontSize: {xs:'1.2rem', md: '1.5rem', lg:'1.75rem'}, fontFamily: 'Neue Montreal, sans-serif', }}>
            Nazarene Baptist Church incorporated on March 24
          </Typography>
        </TimelineContent>
      </TimelineItem>

      <TimelineItem>
        <TimelineOppositeContent color="text.secondary" sx={{ fontSize: {xs:'1.2rem', md: '1.25rem', lg:'1.5rem'}}}>
          1979
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot style={{ backgroundColor: 'black' }}/>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
        <Typography variant="b1" sx={{ fontSize: {xs:'1.2rem', md: '1.5rem', lg:'1.75rem'}, fontFamily: 'Neue Montreal, sans-serif', }}>
        The mortgage was burned
        </Typography>
        </TimelineContent>
      </TimelineItem>

      <TimelineItem>
        <TimelineOppositeContent color="text.secondary" sx={{ fontSize: {xs:'1.2rem', md: '1.25rem', lg:'1.5rem'}}}>
          March 1981
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot style={{ backgroundColor: 'black' }}/>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
        <Typography variant="b1" sx={{ fontSize: {xs:'1.2rem', md: '1.5rem', lg:'1.75rem'}, fontFamily: 'Neue Montreal, sans-serif', }}>
        Rev. William Ashley Hall was called home to be with the Lord.  
        Under the direction of the chairman of the Deacons, Deacon Willie Johnson of Nazarene Baptist Church interviewed several prospective ministers.
        Among those interviewed was Rev. James Thompson who was recommended by his pastor Dr. Harold A. Carter, Sr.,
        Pastor of New Shiloh Baptist Church and the church unanimously agreed upon Rev. James Thompson.
        </Typography>
        </TimelineContent>
      </TimelineItem>

      <TimelineItem>
        <TimelineOppositeContent color="text.secondary" sx={{ fontSize: {xs:'1.2rem', md: '1.25rem', lg:'1.5rem'}}}>
          July 1981
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot style={{ backgroundColor: 'black' }}/>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
        <Typography variant="b1" sx={{ fontSize: {xs:'1.2rem', md: '1.5rem', lg:'1.75rem'}, fontFamily: 'Neue Montreal, sans-serif', }}>
          Reverend Thompson accepted the pastorate of the Nazarene Baptist Church
        </Typography>
        </TimelineContent>
      </TimelineItem>

      <TimelineItem>
        <TimelineOppositeContent color="text.secondary" sx={{ fontSize: {xs:'1.2rem', md: '1.25rem', lg:'1.5rem'}}}>
          August 1981
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot style={{ backgroundColor: 'black' }}/>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
        <Typography variant="b1" sx={{ fontSize: {xs:'1.2rem', md: '1.5rem', lg:'1.75rem'}, fontFamily: 'Neue Montreal, sans-serif', }}>
        Reverend Thompson accepted the pastorate of the Nazarene Baptist Church and began to pastor on August 2, 1981.  
        Pastor Thompson was officially installed on December 6, 1981 thus becoming the second pastor in the history of the Nazarene Baptist Church.
        Under Reverend Thompson's leadership and guidance an Evangelism and Christian Education Ministry began. Persons announced their call to the ministerial ranks and were licensed. Eight sons and four daughters were birthed into the ministry.
        </Typography>
        </TimelineContent>
      </TimelineItem>

      <TimelineItem>
        <TimelineOppositeContent color="text.secondary" sx={{ fontSize: {xs:'1.2rem', md: '1.25rem', lg:'1.5rem'}}}>
          December 1981
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot style={{ backgroundColor: 'black' }}/>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
        <Typography variant="b1" sx={{ fontSize: {xs:'1.2rem', md: '1.5rem', lg:'1.75rem'}, fontFamily: 'Neue Montreal, sans-serif', }}>
        Pastor Thompson was officially installed on December 6, 1981 thus becoming the second pastor in the history of the Nazarene Baptist Church.
        Under Reverend Thompson's leadership and guidance an Evangelism and Christian Education Ministry began. 
        Persons announced their call to the ministerial ranks and were licensed. 
        Eight sons and four daughters were birthed into the ministry.
        </Typography>
        </TimelineContent>
      </TimelineItem>

      <TimelineItem>
        <TimelineOppositeContent color="text.secondary" sx={{ fontSize: {xs:'1.2rem', md: '1.25rem', lg:'1.5rem'}}}>
          2007
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot style={{ backgroundColor: 'black' }}/>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
        <Typography variant="b1" sx={{ fontSize: {xs:'1.2rem', md: '1.5rem', lg:'1.75rem'}, fontFamily: 'Neue Montreal, sans-serif', }}>
        In 2007, Rev. James Thompson and some of the officers of the Nazarene Baptist church, 
        met with the Baltimore City Commission for Historical and Architectural Preservation (CHAP) 
        and by a unanimously endorsed motion, 
        the commission confirmed that the church met criteria #1 and #4 of its standards for Landmark Designation. 
        </Typography>
        </TimelineContent>
      </TimelineItem>

      <TimelineItem>
        <TimelineOppositeContent color="text.secondary" sx={{ fontSize: {xs:'1.2rem', md: '1.25rem', lg:'1.5rem'}}}>
          2009
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot style={{ backgroundColor: 'black' }}/>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
        <Typography variant="b1" sx={{ fontSize: {xs:'1.2rem', md: '1.5rem', lg:'1.75rem'}, fontFamily: 'Neue Montreal, sans-serif', }}>
        Nazarene met the CHAP standard for a historic structure and as of April 2009, the Nazarene Baptist Church became a Baltimore City historical site.
        </Typography>
        </TimelineContent>
      </TimelineItem>

      <TimelineItem>
        <TimelineOppositeContent color="text.secondary" sx={{ fontSize: {xs:'1.2rem', md: '1.25rem', lg:'1.5rem'}}}>
          2010
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot style={{ backgroundColor: 'black' }}/>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
        <Typography variant="b1" sx={{ fontSize: {xs:'1.2rem', md: '1.5rem', lg:'1.75rem'}, fontFamily: 'Neue Montreal, sans-serif', }}>
          On December 4, 2010 Reverend Thompson met with the officers to discuss his successor upon his retirement which would be in the year 2011. On December 21, 2010, a church-wide meeting was held and at this meeting Reverend Thompson asked the congregation the same question concerning his replacement upon his retirement and the members agreed that Reverend Thompson should select his successor.
        </Typography>
        </TimelineContent>
      </TimelineItem>

      <TimelineItem>
        <TimelineOppositeContent color="text.secondary" sx={{ fontSize: {xs:'1.2rem', md: '1.25rem', lg:'1.5rem'}}}>
          May 2011
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot style={{ backgroundColor: 'black' }}/>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
        <Typography variant="b1" sx={{ fontSize: {xs:'1.2rem', md: '1.5rem', lg:'1.75rem'}, fontFamily: 'Neue Montreal, sans-serif', }}>
        An open Council was held in Nazarene Baptist Church to consider ordaining-Minister Alphonzo M. Davis.  
        The purpose of callingthis ordination council together was to ordain Minister Alphonzo M. Davis 
        in order for him to-properly receive the mantle from Pastor James Thompson to become the next Pastor of the Nazarene Baptist Church upon his (Reverend Thompson's) retirement.
        </Typography>
        </TimelineContent>
      </TimelineItem>

      <TimelineItem> 
        <TimelineOppositeContent color="text.secondary" sx={{ fontSize: {xs:'1.2rem', md: '1.25rem', lg:'1.5rem'}}}>
          July 2011
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot style={{ backgroundColor: 'black' }}/>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
        <Typography variant="b1" sx={{ fontSize: {xs:'1.2rem', md: '1.5rem', lg:'1.75rem'}, fontFamily: 'Neue Montreal, sans-serif', }}>
        On July 31st, 2011 Reverend James Thompson officially passed the Pastoral Mantle to Reverend Alphonzo M. Davis. 
        Now the vision continues with Pastor Davis who is one of the sons of Nazarene.
        </Typography>
        </TimelineContent>
      </TimelineItem>

    </Timeline>

    
      
  );
}
