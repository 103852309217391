import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

// My Components
import AppBar from './components/AppBar';
import MobileAppBar from './MobileAppBar';

// MUI Components
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Grid from '@mui/material/Grid';
import Drawer from '@mui/material/Drawer';
import Hidden from '@mui/material/Hidden'; 

// Icons
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import EmailIcon from '@mui/icons-material/Email';
import YouTubeIcon from '@mui/icons-material/YouTube';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AdbIcon from '@mui/icons-material/Adb';
import MenuIcon from '@mui/icons-material/Menu';
import LocalSeeIcon from '@mui/icons-material/LocalSee';

// Logo
//import logo from `${process.env.PUBLIC_URL}/images/logos/chruchLogo-removebg.png`;

//const pages = ['About Us', 'Properties', 'Seminars', 'Mentor Program', 'Loans'];
const aboutDropDown = ['Our Vision', 'Our Pastor', 'Our First Lady', 'Our Youth', 'Our Beliefs', 'Our History', 'Our Ministries']
const connectDropDown = ['Welcome', 'What to Expect']
const resourcesDropDown = ['Prayer Request', 'Praise Reports', 'eMessages', 'Speak Request', 'Member Services', 'Giving'];
const eventsDropDown = ['Calender', 'evites']


function isTabletOriPad() {
  // Check if the User-Agent string contains keywords commonly found in tablet or iPad devices
  const userAgent = navigator.userAgent.toLowerCase();
  return /ipad|tablet|android/.test(userAgent);
}

function AppAppBar() {
  const [scrolling, setScrolling] = useState(false);
  const [openAbout, setOpenAbout] = useState(null);
  const [openResources, setOpenResources] = useState(null);
  const [openProperties, setOpenProperties] = useState(null);
  const [openLearn, setOpenLearn] = useState(null);
  const [openLoan, setOpenLoan] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [expandedLinks, setExpandedLinks] = useState(false);
  const anchorRefProp = useRef(null);
  const anchorRefRes = useRef(null);
  const anchorRefLearn = useRef(null);
  const anchorRefLoan = useRef(null);
  const anchorRefAbout = useRef(null);

  const isMobile = useMediaQuery({ maxWidth: 821 }); 
  const isMobile2 = isTabletOriPad();
  const location = useLocation();
  const isHomePage = location.pathname === '/'; // Check if the current pathname is the homepage ("/")
  const isOnAdminPage = location.pathname.includes('/admin') || location.pathname.includes('/loan-application');; 
  
  
  const handleScroll = () => {
    if (window.scrollY > 100) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    //console.log("Home Page?", isHomePage);
    //console.log("Mobile?", isMobile);
    // Delay setting 'expanded' to true
    let timeoutId;

    if(isHomePage){
      timeoutId = setTimeout(() => {
          setExpanded(true);
      }, 3000); 
    

      timeoutId = setTimeout(() => {
          setExpandedLinks(true);
      }, 4000); 
    } else {
      setExpanded(true);
      setExpandedLinks(true);
    }

    // Clean up event listeners and the timeout
    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  
  // Handle About Dropdown
  const handleMouseLeaveAbout = () => {
    setOpenAbout(false);
  };

  const handleAboutToggle = () => {
    setOpenAbout((prevOpen) => !prevOpen);
    setOpenLearn(false);
    setOpenResources(false);
    setOpenLoan(false);
    setOpenProperties(false);
  }

  const handleCloseAbout = (event) => {
    if (anchorRefProp.current && anchorRefProp.current.contains(event.target)) {
      return;
    }
    setOpenAbout(false);
  };

  function handleListKeyDownAbout(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenAbout(false);
    } else if (event.key === 'Escape') {
      setOpenAbout(false);
    }
  }

  //Close All Dropdowns
  const closeToggels = () => {
    setOpenProperties(false);
    setOpenResources(false);
    setOpenLearn(false);
    setOpenAbout(false);
  }

  return (

    <>

    {(isMobile !== true || isMobile2 !== true) &&

    <Box sx={{ flexGrow: 1 }}>
      <AppBar 
        position="fixed"
        style={{ 
          backgroundColor: scrolling ? 'rgba(15, 15, 15, 1)' : 'transparent', 
          transition: 'background-color 0.5s', 
          //height: '100px',
          zIndex: '1000',
        }}
       
      >
        <Container maxWidth="xl" sx={{p: 1}} >
        
          <Toolbar
            disableGutters
            style={{
              height: '100%', // Added height to center vertically
              width: '100%',
            }}
            //className="app-bar-element"
          >
            <Grid container spacing={0}>
              <Grid xs={3}>
                <Box>
                  <Typography
                    //variant="h5"
                    noWrap
                    component="a"
                    href="/"
                    sx={{
                      display: { xs: 'none', sm: 'flex' },
                      alignItems: 'center', // Center vertically with the Avatar
                      fontFamily: 'Pattaya',
                      fontWeight: 500,
                      letterSpacing: '.1rem',
                      color: 'inherit',
                      textDecoration: 'none',
                      //fontSize: { md: '1rem', lg: '1.25rem', xl: '1.4rem'} //resize based on screensize
                      fontSize: '2vw' //resize based on screensize
                    }}
                  >
                
                    <Avatar src={`${process.env.PUBLIC_URL}/images/logos/chruchLogo-removebg.png`}
                      alt="Logo"
                      sx={{
                        width: { md: '60px', lg: '70px', xl: '80px'},
                        height: { md: '60px', lg: '70px', xl: '80px'},
                        marginRight: '10px',
                      }}
                    />
                
                  Nazarene Baptist
                  </Typography>
                </Box>
              </Grid>

              <Grid xs={6}>
                <Box 
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  height="100%"
                  sx={{ flexGrow: 1, zIndex: 10, /*marginLeft: 'auto', marginRight: 'auto'*/  }}
                >

                  {/* About Dropdown */}
                  <Button
                    key={'aboutus'}
                    component={Link} 
                    to={'/our-vision'}
                    color="inherit"
                    variant="inherit"
                    sx={{ 
                      color: 'white', 
                      //display: 'block', 
                      '&:hover': { textDecoration: 'underline' },
                      fontFamily: 'Merriweather',
                      letterSpacing: '.1rem',
                      //fontSize: { md: '1rem', lg: '1.3rem'}   
                      fontSize: '1.2vw' //resize based on screensize    
                    }}
                    onMouseEnter={handleAboutToggle}
                    aria-controls={openAbout ? 'composition-menu' : undefined}
                    aria-expanded={openAbout ? 'true' : undefined}
                    aria-haspopup="true"
                    ref={anchorRefAbout}
                  >
                    About
                  </Button>

                  <Popper
                    open={openAbout}
                    anchorEl={anchorRefAbout.current}
                    role={undefined}
                    placement="bottom"
                    transition
                    disablePortal
                    onMouseLeave={handleAboutToggle}
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === 'bottom' ? 'left top' : 'left bottom',
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleCloseAbout}>
                            <MenuList
                              autoFocusItem={openAbout}
                              id="composition-menu"
                              aria-labelledby="composition-button"
                              onKeyDown={handleListKeyDownAbout}
                              sx={{flexDirection: 'column'}}
                            >
                              {aboutDropDown.map((prop) => (
                                <MenuItem
                                  key={prop}
                                  component={Link}
                                  to={`/${prop.toLowerCase().replace(/\s/g, '-')}`}
                                  onClick={handleMouseLeaveAbout } // Close the menu when a resource is clicked
                                  
                                >
                                  {prop}
                                </MenuItem>
                              ))}
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper> 

                  <Button
                    key={'giving'}
                    component={Link} 
                    to={'/giving'}
                    color="inherit" 
                    sx={{ 
                      m: {md: 0, lg: 1},
                      color: 'white', 
                      display: 'block', 
                      '&:hover': { textDecoration: 'underline' },
                      fontFamily: 'Merriweather',
                      letterSpacing: '.1rem',
                      //fontSize: { md: '1rem', lg: '1.3rem'}
                      fontSize: '1.2vw' //resize based on screensize    
                    }}
                    onMouseEnter={closeToggels}
                  >
                    Giving
                  </Button>

                  <Button
                    key={'contact'}
                    component={Link} 
                    to={'/contact'}
                    color="inherit" 
                    sx={{ 
                      //my: 2, 
                      //mr: 1, 
                      //ml: 1,
                      m: {md: 0, lg: 1},
                      color: 'white', 
                      display: 'block', 
                      '&:hover': { textDecoration: 'underline' },
                      //fontSize: '17px',
                      fontFamily: 'Merriweather',
                      letterSpacing: '.1rem',
                      //fontSize: { md: '1rem', lg: '1.3rem'}
                      fontSize: '1.2vw' //resize based on screensize    
                    }}
                    onMouseEnter={closeToggels}
                  >
                    Contact
                  </Button>
                </Box>
              </Grid>

              <Grid xs={3}>
                <Box 
                  display="flex"
                  flexDirection="row"
                  justifyContent="right"
                  alignItems="center" // Center vertically
                  width="100%"
                  height="100%"
                  sx={{ flexGrow: 1 }}
                  className={ isHomePage ? (expanded ? 'logo-container-expanded' : 'logo-container-collapsed') : ''}
                >
                  <IconButton color="inherit" href="https://www.instagram.com/nazarenebaptist/" target="_blank" >
                    <InstagramIcon sx={{ fontSize: { md: '25px', lg: '30px', xl: '30px'}}}/>
                  </IconButton>

                  <IconButton color="inherit" href="https://www.facebook.com/THENBCMINISTRIES/" target="_blank">
                    <FacebookIcon sx={{ fontSize: { md: '25px', lg: '30px', xl: '30px'}}}/>
                  </IconButton>

                  <IconButton color="inherit" href="https://www.youtube.com/@nazarenebaptistchurch4671" target="_blank">
                    <YouTubeIcon sx={{ fontSize: { md: '25px', lg: '35px', xl: '35px'}}} />
                  </IconButton>

                  {/*<IconButton color="inherit" href="/" target="_blank">
                    <LocalSeeIcon sx={{ fontSize: { md: '25px', lg: '35px', xl: '35px'}}} />
                  </IconButton>*/}

                  <IconButton color="inherit" href="mailto:info@nazarenebaptist.org" target="_blank">
                    <EmailIcon sx={{ fontSize: { md: '25px', lg: '30px', xl: '30px'}}}/>
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
                   
                          

          
 
          </Toolbar>
        
        </Container>
      </AppBar>
    </Box>

    }

    {isMobile &&
      <MobileAppBar/>
    }

    </>
  );
}

export default AppAppBar;
