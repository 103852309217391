// config.js
const config = {
    development: {
      apiUrl: 'http://localhost:3001/api', // Update with your development backend URL
    },
    production: {
      apiUrl: 'https://test.mammothwebsites.com/api',
    },
  };
  
  export default config;
  