import React, { useState, useEffect, useMemo } from 'react';
import { GoogleMap, Marker, LoadScript, useLoadScript } from "@react-google-maps/api";
import {APIProvider, Map, AdvancedMarker} from '@vis.gl/react-google-maps';
import {MarkerF} from '@react-google-maps/api'
import config from '../config.js';
import axios from 'axios';

function LocationMap() {
  const [location, setLocation] = useState({lat: 53.54992, lng: 10.00678});
  const [mapKey, setMapKey] = useState("");
  const [loading, setLoading] = useState(false);

  // Determine the environment (e.g., development or production)
  const environment = process.env.NODE_ENV || 'development';

  // Set the base URL for Axios requests
  const api = axios.create({
    baseURL:  config[environment].apiUrl.replace('/api', ''),
  });


  useEffect(() => {
    // Fetch Google Map API key
    api.get('/api/getGoogleMapKey')
      .then(response => {
        setMapKey(response.data.apiKey); // Set the API key from the response data
      })
      .catch(error => {
        console.error('Error fetching API key:', error);
        setLoading(true);
      });
  
    // Fetch Location
    api.get('/api/getLocation')
      .then(response => {
        setLocation(response.data);
      })
      .catch(error => {
        console.error("Axios Get Location Error:", error);
        setLoading(true);
      });
  }, []);
  
  const mapContainerStyle = {
    width: '100%',
    height: '75vh',
  };

  // Render the map or an error message
  return (
    <div>
    {!location && !loading ? (
      <h1>Loading Map...</h1>
    ) : (
      mapKey ? ( // Only render LoadScript if mapKey is available
        <LoadScript async googleMapsApiKey={mapKey}>
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={{ lat: location.lat, lng: location.lng }}
            zoom={17}
          >
            <MarkerF position={{ lat: location.lat, lng: location.lng }} />
          </GoogleMap>
        </LoadScript>
      ) : (
        <p>Loading map...</p> // Optionally show a loading message
      )
    )}
  </div>
  );
}

export default LocationMap;
